
import {computed, defineComponent, onMounted, onUpdated, ref} from "vue";
import {removeLinkActive} from "@/core/helpers/dom";
import store from "@/store";
import {HandleState, LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Cards from "@/components/base/common/Cards.vue";
import PaymentCard from "@/views/payment/PaymentCard.vue";
import Swal from "sweetalert2";
import PaymentService from "@/core/services/PaymentService";
import Row from "@/components/base/common/Row.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import {ModelAddress} from "@/core/entity/IAddress";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {PAYMENT_METHOD, PAYMENT_STATUS, PAYMENT_STATUS_PENDING, PAYMENT_SUB_TYPE} from "@/core/config/Constant";
import {ILocation} from "@/core/entity/ILocation";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import {Field} from "vee-validate";
import ExchangeRateService from "@/core/services/ExchangeRateService";

export default defineComponent({
  name: "Payments",
  components: {
    Field,
    DateTimeSelect,
    BaseSelect, BaseRadio, BaseForm, BaseModal, CurrencyFormat, Row, PaymentCard, Cards, QuickAction
  },
  props: {
    id: {type: String}
  },
  setup(props) {
    const scanCheck = ref(false);

    onUpdated(() => {
      removeLinkActive()
    })
    onMounted(() => {
      removeLinkActive()
    })

    const state = ref<any>({id: '', payment: {address: {stateName: ''}}, submitting: false})
    const invoice = computed(() => store.state.InvoiceModule.invoice);
    const filterObject = ref({invoiceId: props.id})
    const paymentPage = computed(() => store.state.PaymentModule.paymentPage);
    const countries = computed<ILocation[]>(() => store.state.LocationModule.countries)
    const states = computed<ILocation[]>(() => store.state.LocationModule.states)
    const currencies = computed(() => store.state.ExchangeRateModule.currencies);
    return {
      ...LoadFilterObjects(Actions.LOAD_PAYMENTS, filterObject.value, ['parent']),
      invoice,
      paymentPage,
      state,
      ...HandleState(),
      countries,
      states,
      currencies,
      PAYMENT_SUB_TYPE,
      PAYMENT_STATUS,
      PAYMENT_METHOD,
      PAYMENT_STATUS_PENDING,
      scanCheck,
    }
  },
  methods: {
    onEdit(payment) {
      PaymentService.sanctionsCheck(payment.id).then(res => {
        this.scanCheck = res.result
      })
      this.state.id = payment.id;
      this.state.payment = {
        type: payment.type,
        status: payment.status,
        subType: payment.subType,
        name: payment.name,
        address: payment.address ? payment.address : new ModelAddress(),
        amount: payment.amount,
        currency: payment.currency,
        method: payment.method ? payment.method : 'NA',
        amountInvCurrency: payment.amountInvCurrency,
        number: payment.number,
        paidDt: payment.paidDt,
        paidThrough: payment.paidThrough
      }
      const base = this.$refs.paymentSearchEditRef as typeof BaseModal;
      base.showBaseModal();
    },
    onVoid(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: " Please confirm voiding the payment!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await PaymentService.delete(id).then(() => {
            this.paginationLoad();
            store.dispatch(Actions.LOAD_INVOICE, this.id);
          })
        }
      })
    },
    onCancel() {
      const base = this.$refs.paymentSearchEditRef as typeof BaseModal;
      base.showBaseModal();
    },
    onStateNameSelect(stateName) {
      const name = this.states.find((item) => item.code === stateName)?.name
      if (name && this?.state && this.state?.payment && this?.state?.payment?.address) {
        this.state.payment.address.stateName = name
      }
    },
    async submit() {
      this.state.submitting = true;
      await this.calculateAmountInvCurrency();
      PaymentService.update(this.state.id, this.state.payment).then(() => {
        this.paginationLoad()
        const base = this.$refs.paymentSearchEditRef as typeof BaseModal;
        store.dispatch(Actions.LOAD_INVOICE, this.invoice.id)
        base.hideBaseModal();
      }).finally(() => {
        this.state.submitting = false;
      })
    },
    onCountryNameSelect(countryName) {
      const name = this.countries.find((item) => item.code === countryName)?.name
      if (name && this?.state?.payment && this?.state?.payment?.address) this.state.payment.address.countryName = name
    },
    deletePayment(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await PaymentService.delete(id).then(() => {
            this.paginationLoad();
            store.dispatch(Actions.LOAD_INVOICE, this.id);
          })
        }
      })
    },
    async calculateAmountInvCurrency() {
      this.state.submitting = true;
      if (this.state.payment.currency === this.invoice.currency) {
        this.state.payment.amountInvCurrency = this.state.payment.amount;
      } else {
        const exchanged = await ExchangeRateService.exchangeRate(this.state.payment.currency, this.invoice.currency, this.state.payment.amount);
        this.state.payment.amountInvCurrency = exchanged.converted;
      }
      this.state.submitting = false
    }
  }
})
